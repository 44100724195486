<template>
  <!-- https://www.figma.com/file/xa8FrgPPuqXTcsfZdtUNZP/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8C%D0%B5?type=design&node-id=1296-157872&mode=design&t=ZxiRTo7fIj7ITSH6-0 -->
  <div class="promises-container">
    <div class="promises">
      <p class="promises__title">Обещание</p>
      <p class="promises__subtitle">
        Мы создали медицинский раздел, чтобы вы находили только проверенную и безопасную информацию
        о здоровье на русском языке.
      </p>
      <div class="promises-block">
        <div
          v-for="(promise, index) in promises"
          :key="index"
          class="promise"
        >
          <SvgAsset
            :content="promise.img"
            class="promise__image"
          />
          <p class="promise__text">
            {{ promise.text }}
          </p>
        </div>
      </div>
      <div class="read-also">
        <a
          href="/health/o-proekte/"
          class="read-also__text"
          @click="sendAnalytics"
        >
          Читать ещё
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { getFullUrl } from '~/utils';
  import doctorContent from '~/assets/images/doctor.svg?raw';
  import medicalPractitioner from '~/assets/images/medical-practitioner.svg?raw';
  import medicalFile from '~/assets/images/medical-file.svg?raw';
  import heartHand from '~/assets/images/heart-hand.svg?raw';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';

  export default defineNuxtComponent({
    name: 'BlockPromise',

    components: {
      SvgAsset,
    },

    data() {
      return {
        promises: [
          {
            img: doctorContent,
            text: 'Над разделом работают профессиональные медицинские авторы и редакторы',
          },
          {
            img: medicalPractitioner,
            text: 'Мы рецензируем статьи с врачами из ведущих клиник доказательной медицины России',
          },
          {
            img: medicalFile,
            text: 'Мы опираемся на научные источники, которым доверяют эксперты по всему миру, и оставляем ссылки на них в материалах',
          },
          {
            img: heartHand,
            text: 'Мы регулярно обновляем тексты, чтобы вы получали самые свежие рекомендации о здоровье',
          },
        ],
      };
    },

    methods: {
      sendAnalytics() {
        this.$sendYandexMetrika({
          level1: 'Клик_Обещание',
          level4: getFullUrl('/health/o-proekte/'),
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .promises-container {
    padding: 16px;
    background-color: #fff;
    @include tablet {
      padding: 24px;
    }
  }

  .promises {
    padding: 16px;
    background-color: #f8f9fb;
    word-break: break-word;
    @include tablet {
      padding: 24px;
    }

    &__title,
    &__subtitle {
      font-weight: 600;
      font-family: $secondFont;
      color: #000;
    }

    &__title {
      font-size: 26px;
      line-height: 34px;
      @include tablet {
        font-size: 28px;
        line-height: 36px;
      }
    }

    &__subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
      @include tablet {
        margin-top: 24px;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .read-also {
    text-align: center;
    padding: 8px 0px;

    @include tablet {
      margin-top: 24px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      font-family: $mainFont;
      color: var(--main-color);
      text-decoration: none;
      @include hover {
        color: var(--hover-color);
      }
    }
  }

  .promises-block {
    margin-top: 16px;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 16px;
      margin-top: 24px;

      & > .promise:nth-child(n + 3) {
        margin-top: 20px;
      }
    }

    & > .promise:not(:last-child) {
      border-bottom: 1px solid #fff;
      @include tablet {
        border-bottom: none;
      }
    }
  }

  .promise {
    display: flex;
    align-items: center;
    padding: 16px 0;
    @include tablet {
      display: block;
      width: calc(50% - 8px);
      padding: 0;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      font-family: $mainFont;
      color: #000;
      margin-left: 12px;
      @include tablet {
        font-size: 18px;
        line-height: 26px;
        margin-left: 0;
        margin-top: 12px;
      }
    }

    &__image {
      color: var(--main-color);
      :deep() {
        svg {
          min-width: 48px;
          max-width: 48px;
          min-height: 48px;
          max-height: 48px;
        }
      }
    }
  }
</style>
