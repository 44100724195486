<template lang="pug">
.widgets-renderer(:style="rootElementStyle")
  slot(name="partner-banner")
  section.widgets-renderer__widget(
    v-for="(widget, index) in showWidgets"
    ref="widgetEl"
    :class="getWidgetClassNames(widget)"
    :key="widget.id"
    :data-key="widget.id"
  )
    WidgetsRenderer(
      :widget="widget"
      :inSidebar="inSidebar"
      :recipesWidgetDisplayType="recipesWidgetDisplayType"
      @recipes-widget-show-more="sendAnalyticsRecipesWidget($event, true)"
      @recipes-widget-click-recipe="sendAnalyticsRecipesWidget($event)"
      :recipesWidgetEventCategory="recipesWidgetEventCategory"
    )
  section.widgets-renderer__sticky(v-if="inSidebar")
    section.widgets-renderer__widget(
      v-for="(widget, index) in stickyWidgets"
      ref="widgetEl"
      :class="getWidgetClassNames(widget)"
      :key="widget.id"
      :data-key="widget.id"
    )
      WidgetsRenderer(
        :widget="widget"
        :inSidebar="inSidebar"
        :recipesWidgetDisplayType="recipesWidgetDisplayType"
        :recipesWidgetEventCategory="recipesWidgetEventCategory"
        @recipes-widget-show-more="sendAnalyticsRecipesWidget($event, true)"
        @recipes-widget-click-recipe="sendAnalyticsRecipesWidget($event)"
      )

</template>

<script lang="ts">
  import type { PropType } from 'vue';
  // import { vResizeObserver } from '@vueuse/components';

  import WidgetsRenderer from '~/components/WidgetsRenderer/WidgetsRenderer.vue';

  import { isTheRecipePage, isRecipesPage, isRecipesMainPage } from '~/utils/router';

  const MARGIN_BOTTOM_CLASS_NAME = 'add-margin-bottom';

  export default defineNuxtComponent({
    name: 'WidgetsRendererContainer',
    components: {
      WidgetsRenderer,
    },

    setup() {
      const widgetEl = ref<null | HTMLDivElement>(null);

      useResizeObserver(widgetEl, (entries) => {
        entries.forEach((entry) => {
          if (entry.contentRect.height > 0) {
            entry.target?.classList.add(MARGIN_BOTTOM_CLASS_NAME);
          } else {
            entry.target?.classList.remove(MARGIN_BOTTOM_CLASS_NAME);
          }
        });
      });

      return {
        widgetEl,
      };
    },

    props: {
      widgets: {
        type: Array as PropType<TWidget[] | WidgetInRecipes[]>,
        required: true,
      },
      widgetClassName: {
        type: String as PropType<string>,
        default: 'widgets-renderer-item',
      },
      rootElementStyle: {
        type: Object as PropType<object>,
        required: false,
        default: () => {},
      },
      inSidebar: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      recipesWidgetDisplayType: {
        type: String as PropType<'default' | 'big-tiles'>,
        default: 'default',
      },
    },
    data: () => ({
      // список виджетов, которые должны быть sticky,
      // кроме последнего, который sticky по умолчанию
      widgetsTypeSticky: ['posts-collection'],
    }),
    computed: {
      showWidgets() {
        if (!this.inSidebar) {
          return this.widgets;
        }

        return (
          this.widgets
            // убираем последний виджет, тк он должен прилипать
            .slice(0, -1)
            // убираем остальные виджеты, которые должны прилипать
            .filter((widget) => {
              const isSticky = this.widgetsTypeSticky.find(
                (widgetType) => widgetType === widget.type,
              );
              return !isSticky;
            })
        );
      },
      stickyWidgets() {
        if (!this.inSidebar) {
          return;
        }

        const widgetsSticky = this.widgets.slice(0, -1).filter((widget) => {
          return this.widgetsTypeSticky.some((widgetType) => widgetType === widget.type);
        });

        return [...widgetsSticky, ...this.widgets.slice(-1)];
      },

      // * аналитика рецепты
      recipesWidgetEventCategory(): string {
        const routeName = useRoute().name;
        switch (true) {
          case isRecipesMainPage(routeName):
            return 'Главная';
          case isTheRecipePage(routeName):
            return 'Рецепты';
          case isRecipesPage(routeName):
            return 'Таксономия';
          default:
            return 'unknown';
        }
      },
    },

    methods: {
      handleResize({ target, contentRect: { height } }: ResizeObserverEntry): void {
        if (height > 0) {
          target.classList.add(MARGIN_BOTTOM_CLASS_NAME);
        } else {
          target.classList.remove(MARGIN_BOTTOM_CLASS_NAME);
        }
      },

      getWidgetClassNames(widget: TWidget): Array<string> {
        const { widgetClassName } = this;
        const typeClass = `widgets-renderer-item_${widget.type}`;

        let taxonomyPostsWidgetClassName = '';
        if (widget.type === 'taxonomy-posts') {
          const taxonomyPostsWidgetType = (widget?.items?.length ?? 0) > 2 ? 'slider' : 'cards';
          taxonomyPostsWidgetClassName = `widgets-renderer-item_${widget.type}-${taxonomyPostsWidgetType}`;
        }

        let marginClass = '';
        // Добавляем маржин по умолчанию для ssr, убирается в handleResize если не нужен
        // iframe невозможно ssr'ить потому для них не добавляем
        if (widget.type !== 'html') {
          marginClass = MARGIN_BOTTOM_CLASS_NAME;
        }

        return [widgetClassName, typeClass, taxonomyPostsWidgetClassName, marginClass];
      },

      sendAnalyticsRecipesWidget(
        recipeAnalyticsData: { slug: string; category: string },
        showMore?: boolean,
      ): void {
        if (!this.inSidebar) {
          if (showMore) {
            this.$emit('recipes-widget-show-more', recipeAnalyticsData.slug);
          } else {
            this.$emit('recipes-widget-click-recipe', recipeAnalyticsData.slug);
          }
        }
      },

      // Получение номера виджета "список рецептов" среди виджетов с таким же типом
      getRecipeListWidgetNumber(id: string): number {
        const widgetIndex = this.widgets
          .filter((item) => item.type === 'recipes-list')
          .findIndex((item) => item.id === id);
        return widgetIndex + 1;
      },
    },
  });
</script>

<style lang="scss">
  .widgets-renderer {
    .widget-between-posts.add-margin-bottom {
      .lightgrey-box {
        margin: 24px -16px 0;
      }
    }

    .other-content__main & {
      max-width: 100vw;
      @include tablet {
        min-width: 100%;
        max-width: 640px;
      }

      // для заголовков идущие первыми в блоке
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 24px;
      }

      // для остальных заголовков в блоке
      * ~ h2 {
        &:not(.lh-middle-article-card__title) {
          margin-top: 32px;

          @include tablet {
            margin-top: 40px;
          }
        }
      }

      * ~ h3,
      * ~ h4,
      * ~ h5,
      * ~ h6 {
        &:not(.lh-middle-article-card__title) {
          margin-top: 24px;
        }
      }
    }

    &__sticky {
      content-visibility: auto;
      contain-intrinsic-width: auto 300px;
      contain-intrinsic-height: auto 100vh;

      @mixin sticky-styles {
        position: sticky;
        top: 48px + 24px;
      }
      .other-content__right-sidebar & {
        @include sticky-styles;
      }
      .recipes-layout__right-sidebar & {
        @include sticky-styles;
      }
    }

    // снижает количество элементов для первоначальной отрисовки
    // реклама или кастомный блок, не знаем размеров
    // кроме цитат, т.к. они обрезаются из-за отрицательного margin
    &-item_parsed-html:not(:has(.lightgrey-box, .grey-box)) {
      content-visibility: auto;
    }

    &-item_posts-collection {
      content-visibility: auto;
      contain-intrinsic-width: auto 100vw;
      contain-intrinsic-height: auto 570px;

      @include tablet {
        contain-intrinsic-width: auto 780px;
        contain-intrinsic-height: auto 340px;
      }
    }

    &-item_taxonomy-posts-slider {
      content-visibility: auto;
      contain-intrinsic-width: auto 100vw;
      contain-intrinsic-height: auto 330px;

      @include tablet {
        contain-intrinsic-width: auto 780px;
      }
    }

    &-item_recipes-list {
      content-visibility: auto;
      contain-intrinsic-width: auto 100vw;
      contain-intrinsic-height: auto 480px;

      @include tablet {
        contain-intrinsic-width: auto 640px;
        contain-intrinsic-height: auto 640px;
      }
    }
  }

  .other-content__sidebar-widget {
    margin: 0 auto;
    &:last-child {
      position: sticky;
      top: 48px + 24px;
    }
  }

  .widget-below-header {
    overflow: hidden;

    .custom-html-widget {
      text-align: center;
    }
  }
  .add-margin-bottom {
    margin-bottom: 24px;
  }

  .page__widget-above-header.add-margin-bottom,
  .widget-below-header.add-margin-bottom {
    margin-bottom: 0;
  }

  .widget-between-posts.add-margin-bottom {
    margin-bottom: 0;
  }

  .homepage__widgets-above-header .add-margin-bottom {
    margin-bottom: 0;
  }

  .above-comments-widgets__container,
  .below-comments-widgets__container {
    .widgets-renderer-item_taxonomy-posts-cards + .widgets-renderer-item_taxonomy-posts-cards {
      margin-top: -24px;

      @include tablet {
        margin-top: -25px;
      }
    }
  }

  .below-comments-widgets__container {
    @include broadside {
      @include tablet {
        max-width: 732px;
        min-width: unset;
        margin: 0 auto;
      }
    }
  }

  .below-comments-widgets__container .add-margin-bottom:last-child {
    @include two-column {
      margin-bottom: 0;
    }

    @include template-table-of-contents {
      @include big-tablet {
        margin-bottom: 0;
      }
    }
  }

  .other-content__sidebar-widgets .add-margin-bottom:last-child {
    @include two-column {
      margin-bottom: 0;
    }
    @include type-page {
      margin-bottom: 24px;
    }
  }

  // добавляется в джампер постах в сафари fix LH-1210
  .other-content__sidebar-widget.set-position-fixed {
    position: fixed;
    width: 300px;
  }
</style>
