<template lang="pug">
.the-content-container.single-article__post-content
  TheContent(
    v-for="item of arrayOfHTMLContents"
    :key="item.id"
    :elem="item"
    :location="location"
  )
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import { resetCSSAndDOM } from './utils';
  import TheContent from '~/components/content/TheContent';
  import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
  import { Scripts } from '~/libs/Scripts';

  let scriptsAdder: null | IScripts = null;

  export default defineNuxtComponent({
    name: 'HtmlWidgetContainer',
    components: { TheContent },
    props: {
      widgetsArray: { type: Array as PropType<TContent[]>, required: true },
      location: {
        type: String as PropType<string>,
        // required: true,
        default: null,
      },
    },
    computed: {
      arrayOfHTMLContents(): TContent[] {
        return separateScriptsAndContent(this.widgetsArray).content;
      },
      routePath(): string {
        return useRoute().path;
      },
      adfoxWidgets(): TContent[] {
        const adfoxWidgetsContainer: TContent[] = [];

        fillContainer(this.widgetsArray);

        function fillContainer(contentArray: TContent[]): void {
          contentArray.forEach((contentItem: TContent) => {
            const idAttribute = contentItem?.attributes?.id;
            if (idAttribute !== undefined && idAttribute.includes('adfox_')) {
              adfoxWidgetsContainer.push(contentItem);
            }
            // рекурсивно получаем адфокс-виджеты
            if (contentItem.children.length) {
              fillContainer(contentItem.children);
            }
          });
        }

        return adfoxWidgetsContainer;
      },
    },
    watch: {
      routePath() {
        this.reloadAdfoxWidgets();
      },
    },
    mounted() {
      if (this.widgetsArray.length) {
        const { inlineScripts, scripts } = separateScriptsAndContent(this.widgetsArray);

        scriptsAdder = new Scripts(scripts, inlineScripts);
        scriptsAdder.addScripts();
      }
    },
    beforeUnmount() {
      // вызываем destroy т.к. adfox вешает cb на scroll,
      // в котором устанавливает padding-bottom: 120px
      this.adfoxWidgets.forEach((w: TContent) => {
        const idAttribute = w?.attributes?.id;
        if (idAttribute) {
          window?.Ya?.adfoxCode?.destroy(idAttribute);
        }
      });
      // после destroy в resetCSSAndDOM сбрасывается padding-bottom
      resetCSSAndDOM();
      scriptsAdder && scriptsAdder.removeScripts();
    },
    methods: {
      reloadAdfoxWidgets() {
        this.adfoxWidgets.forEach((w: TContent) => {
          const idAttribute = w?.attributes?.id;
          if (idAttribute) {
            window?.Ya?.adfoxCode?.reload(idAttribute);
          }
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .the-content-container {
    @mixin padding {
      padding: 0 16px;
      @include tablet {
        padding: 0 24px;
      }
    }

    @include widget-between-posts {
      max-width: 100vw;
      margin: 0;
      padding: 0 16px;
    }

    @include widget-below-comments {
      @include padding;

      div[id^='adfox_'] {
        margin: 0 -16px;

        @include tablet {
          margin: 0 -24px;
        }
      }
    }

    @include widget-footer {
      @include padding;

      div[id^='adfox_'] {
        margin: 0 -16px;

        @include tablet {
          margin: 0 -24px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .the-content-container {
    div[id*='adfox_'] {
      overflow: hidden;
      display: flex;
      justify-content: center;

      // для сброса
      // iframe {
      // border: none;
      // width: 1px;
      // min-width: 100%;
      // }
      // из _base.scss
      // fix LH-1193
      iframe {
        border: inherit;
        width: inherit;
        min-width: inherit;
      }

      // fix LH-1285
      a {
        border: none !important;
      }
    }
  }
</style>
