<template lang="pug">
.article-list-container
  ArticleList(
    :articles="articles",
    :title="title",
  )
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';

  import ArticleList from '~/components/ArticleList/ArticleList.vue';

  import { eventCategory } from '~/constants/analytics';
  import { isHomePage, isArticlePage, isTopPage, isArchivePage } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'ArticleListContainer',

    components: {
      ArticleList,
    },

    props: {
      title: { type: String, required: true } as PropOptions<string>,
      articles: { type: Array, required: true } as PropOptions<Array<ITopArticle>>,
    },

    methods: {
      getCategory(name: string) {
        switch (true) {
          case isHomePage(name):
            return eventCategory.home;
          case isTopPage(name):
            return eventCategory.top;
          case isArchivePage(name):
            return eventCategory.archive;
          case isArticlePage(name):
            return eventCategory.article;
          default:
            return '';
        }
      },
    },
  });
</script>
