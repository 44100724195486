<template lang="pug">
  .article-list-content
    .article-list
      a.article-list__title(
        href="/top/"
        @click="onClickToTop"
      ) {{title}}
      TheLink.article-list__article(
          v-for="(article,idx) in articles"
          :key="article.id"
          :link="getArticleUrl(article)"
          :title="article.title"
          @click.native="$emit('item-click', getArticleUrl(article), $route.name)"
      )
        span.article-list__article-order {{1 + idx}}
        p.article-list__article-title(v-html="article.title")
</template>

<script lang="ts">
  import TheLink from '~/components/TheLink.vue';

  export default defineNuxtComponent({
    name: 'ArticleList',
    components: { TheLink },

    props: {
      articles: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      abTitles: {
        type: Object,
        required: true,
      },
    },

    methods: {
      getArticleUrl(article: ITopArticle): string {
        const { url, teaserUrl } = article;
        return teaserUrl || url;
      },
      onClickToTop() {
        this.$emit('click-to-top', '/top', this.$route.name);
      },
    },
  });
</script>

<style scoped lang="scss">
  $light-purple: #e1eafd;

  .article-list-content {
    border-top: 3px solid var(--main-color);
  }

  .article-list {
    max-width: 300px;
    padding: 24px 0 16px 0;
    margin-bottom: 24px;
    background: #ffffff;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    position: relative;

    &__title {
      display: inline-block;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 4px;
      text-decoration: none;

      @include fontH3;

      @include hover {
        color: var(--hover-color);
      }

      .other-content__right-sidebar & {
        @include fontH4;
      }
    }

    &__article {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding: 16px 16px;
      text-decoration: none;

      &:not(:last-child):before {
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background-color: #e7e7e7;
        content: '';
      }

      @include hover {
        &:after {
          position: absolute;
          left: -1px;
          top: -1px;

          box-sizing: border-box;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          content: '';
          border: 2px solid var(--main-color);
        }
      }
    }

    &__article-order {
      flex: 0 0 24px;
      margin-right: 4px;
      text-align: center;
      line-height: 24px;
      font-family: $secondFont;
      font-size: 16px;
      color: $main;
      font-weight: bold;
    }

    &__article-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      color: black;
      text-decoration: none;

      @include fontNormalText;

      .other-content__right-sidebar & {
        @include fontTextBtn;
        font-weight: 400;
      }
    }
  }
</style>
