<template>
  <div
    ref="swiperTile"
    class="swiper tiles"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="swiper-wrapper"
      :class="computedClass"
    >
      <TileItem
        v-for="(tile, idx) in tiles"
        :key="idx"
        :tile="tile"
        class="swiper-slide tile"
        @click-tile="$emit('clickTile', tile)"
      />
    </div>
    <button
      ref="swiperPrevTile"
      class="swiper-button-prev"
      :class="prevButtonClasses"
    />
    <button
      ref="swiperNextTile"
      class="swiper-button-next"
      :class="nextButtonClasses"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { Swiper } from 'swiper';
  import 'swiper/css';
  import type { TTile } from './types';
  import TileItem from '@/components/Tile/TileItem.vue';

  export default defineNuxtComponent({
    name: 'TileContainer',

    components: {
      TileItem,
    },

    props: {
      tiles: {
        type: Array as PropType<Array<TTile>>,
        required: true,
      },
      enableSwiper: {
        type: Boolean,
        required: true,
      },
      showArrows: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        swiper: {} as Swiper,
        isFirstSendEventSwiperScroll: true,
        showNextButton: false,
        showPrevButton: false,
      };
    },

    computed: {
      computedClass(): Record<string, boolean> {
        return {
          'swiper--disabled': !this.enableSwiper,
        };
      },
      prevButtonClasses(): Record<string, boolean> {
        return {
          visible: this.showPrevButton && this.showArrows,
        };
      },

      nextButtonClasses(): Record<string, boolean> {
        return {
          visible: this.showNextButton && this.showArrows,
        };
      },
    },

    async mounted() {
      const { default: Swiper } = await import('swiper');
      const { Navigation } = await import('swiper/modules');
      const TileSwiper = this.$refs.swiperTile as HTMLElement;

      this.swiper = new Swiper(TileSwiper, {
        modules: [Navigation],
        spaceBetween: 12,
        observer: true,
        observeParents: true,
        centeredSlidesBounds: true,
        slidesPerView: 'auto',
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16,
        navigation: {
          prevEl: this.$refs.swiperPrevTile as HTMLElement,
          nextEl: this.$refs.swiperNextTile as HTMLElement,
          disabledClass: 'swiper-button-hidden',
        },
      });

      this.swiper.on('slideChange', () => {
        if (this.isFirstSendEventSwiperScroll) {
          this.$emit('scroll-swiper');
          this.isFirstSendEventSwiperScroll = false;
        }
      });

      this.enableSwiper ? this.swiper.enable() : this.swiper.disable();
    },

    methods: {
      handleMouseLeave(): void {
        this.showNextButton = this.showPrevButton = false;
      },

      handleMouseMove({ currentTarget, clientX }: MouseEvent): void {
        if (!currentTarget) {
          return;
        }

        const { left, right } = (currentTarget as HTMLElement)?.getBoundingClientRect();
        const middle = (right - left) / 2;
        const mouseX = clientX - left;

        this.showPrevButton = mouseX < middle && !this.swiper?.isBeginning;
        this.showNextButton = mouseX > middle && !this.swiper?.isEnd;
      },
    },
  });
</script>

<style lang="scss" scoped>
  //@use 'swiper/swiper.css' as *;

  :deep() {
    .swiper--disabled {
      transform: none !important;
    }
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-button-hidden {
      position: absolute;
      width: 32px;
      height: 32px;

      border-radius: 32px;
      background: #fff;
      border: none;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);

      opacity: 0;
      transition: 0.5s;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg id="direction/right/24px"%3E%3Cpath id="Rectangle 438" d="M13.8999 6.19238L7.99609 12.0962L13.8999 18" stroke="%23969698" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/g%3E%3C/svg%3E%0A');
        background-size: contain;
      }
    }

    .swiper-button-prev {
      top: 50px;
      left: -16px;

      &.visible {
        left: 24px;
        opacity: 1;
      }
    }

    .swiper-button-next {
      top: 50px;
      right: -16px;

      &:after {
        scale: -1;
      }

      &.visible {
        right: 24px;
        opacity: 1;
      }
    }
  }
  .tiles {
    height: 100%;
    display: flex;
  }
</style>
