<script lang="ts" setup>
  import { parseData } from '@devhacker/shared/utils/parseData';
  import CommentsWidget from '~/components/commentWidget/CommentsWidget.vue';
  import { FetchLastCommentsSchema } from '~/schemas/talker';
  import { getFullUrl } from '~/utils';
  import { cacheApiResponseWithRedis } from '@devhacker/shared/utils/cacheApiResponseWithRedis';

  const {
    data: commentsLast,
    error,
    status,
  } = useAsyncData(async () => {
    // TODO: FETCH TYPES
    const { $talkerApi, $redisClient } = useNuxtApp();

    const { data } = await cacheApiResponseWithRedis(
      'FETCH_LAST_COMMENTS',
      () => $talkerApi.fetchLastComments(),
      $redisClient,
    );

    return parseData(data, FetchLastCommentsSchema);
  });

  if (error.value) {
    console.error('Error fetching comments inside CommentsWidgetContainer', error);
  }

  const sendAnalyticsEvent = (element: string, postSlug: string) => {
    useNuxtApp().$sendYandexMetrika({
      level1: 'Комментарии_переход к комментариям',
      level4: getFullUrl(postSlug),
      level6: `Виджет "${element} комментарии"`,
    });
  };
</script>

<template>
  <div
    v-if="commentsLast && status === 'success'"
    class="comments"
  >
    <CommentsWidget
      title="Новые комментарии"
      :comments-list="commentsLast.data"
      @click-item="(postSlug) => sendAnalyticsEvent('Новые', postSlug)"
    />
  </div>
</template>

<style lang="scss" scoped>
  .comments {
    width: 300px;
  }
</style>
