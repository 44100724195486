<template>
  <div class="comments__content">
    <div class="comments__list">
      <p class="comments__title">
        {{ title }}
      </p>
      <CommentCard
        v-for="item of commentsList"
        :key="item.id"
        :content="item.content"
        :files="item.files"
        :author="item.author"
        :likes-count="item.users_liked_cnt"
        :dislikes-count="item.users_disliked_cnt"
        :avatar="item.avatar"
        :timestamp="item.date_gmt"
        :title="item.post_title || ''"
        :post-slug="item.post && item.post.slug"
        :post-type="item.post && item.post.type"
        :comment-id="item.id"
        @clicked-on-item="$emit('click-item', item.post.slug)"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import CommentCard from '~/components/CommentCard/CommentCard.vue';

  export default defineNuxtComponent({
    name: 'CommentsWidget',

    components: {
      CommentCard,
    },

    props: {
      commentsList: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },

    computed: {
      isShowComments(): boolean {
        return Boolean(this.commentsList?.length);
      },
    },
  });
</script>

<style lang="scss" scoped>
  $borderColor: #e7e7e7;
  $backgroundColor: white;
  .comments__title {
    margin-bottom: 4px;
    margin-left: 16px;

    @include fontH3;

    .other-content__right-sidebar & {
      @include fontH4;
    }
  }

  .comments__content {
    border-top: 3px solid var(--main-color);
  }

  .comments__list {
    padding: 24px 0 16px;

    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    display: flex;
    flex-direction: column;
    background-color: $backgroundColor;

    &:first-child {
      margin-bottom: 24px;
    }
  }
</style>
