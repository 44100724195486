<template lang="pug">
  .quick-poll__container
    Poll(
      v-if="isValidQuickPoll"
      :id="quickPoll.id"
      :question="quickPoll.question"
      :answers="quickPoll.answers"
      :state="quickPoll.state"
      :statistics="quickPoll.statistics || null"
      :theme="quickPoll.theme.toString()"
      :selectedAnswer="quickPoll.selectedAnswer"
      :testPixel="quickPoll.testPixel"
      :inSidebar="inSidebar"
      :location="location"
      @clicked-on-answer="onClickedAnswer"
      @appear="onAppearPoll"
    )
      .quick-poll__article(
        :class="quickPollArticleClass"
      )
        TransitionLayout
          PollArticleCard(
            v-if="isVisibleArticlePoll"
            :url="quickPoll.article.url"
            :title="quickPoll.article.title"
            :cover="quickPoll.article.cover"
            :defaultCover="quickPoll.article.default_cover"
            :imagesHost="imagesHost"
            :location="location"
            @link-click="onLinkClick"
          )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import Poll from '@/components/lh-ui/Poll/Poll.vue';
  import PollArticleCard from '@/components/lh-ui/PollArticleCard/PollArticleCard.vue';
  import { QUIZ_API_URL } from '@/constants/config';
  import TransitionLayout from '@/components/transition.vue';
  import { useQuickPollStore } from '~/store/quickPolls';

  export default defineNuxtComponent({
    name: 'QuickPollContainer',

    components: {
      Poll,
      PollArticleCard,
      TransitionLayout,
    },

    props: {
      inSidebar: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      location: {
        type: String as PropType<string>,
        required: true,
      },
      widgetId: {
        type: String as PropType<string>,
        required: true,
      },
    },

    computed: {
      imagesHost(): string {
        return QUIZ_API_URL;
      },
      quickPollId(): string {
        return useQuickPollStore().pollsByLocation.byId[`${this.widgetId}`];
      },
      quickPoll(): QuickPoll {
        return useQuickPollStore().polls.byId[this.quickPollId];
      },
      isValidQuickPoll(): boolean {
        const { quickPoll } = this;
        return Boolean(
          quickPoll &&
            quickPoll.question &&
            quickPoll.answers?.length === 2 &&
            quickPoll.article?.url &&
            quickPoll.article?.title,
        );
      },
      quickPollIsInitial(): boolean {
        return this.quickPoll && this.quickPoll.state === 'initial';
      },
      quickPollArticleClass(): any {
        return {
          'quick-poll__article--visible': this.isVisibleArticlePoll,
        };
      },
      isVisibleArticlePoll(): boolean {
        return Boolean(this.quickPoll && this.quickPoll?.state === 'answered');
      },
    },

    methods: {
      onAppearPoll(quickPollId: number): void {
        this.$quizApi.setQuickPollViewed(quickPollId).then(() => {
          const localStorageQuickPolls = JSON.parse(localStorage.getItem('quick-polls') as string);
          const newLocalStorageQuickPolls = localStorageQuickPolls || {};

          Object.keys(newLocalStorageQuickPolls).forEach((quickPollId) => {
            if (newLocalStorageQuickPolls[quickPollId].lastWidgetId === this.widgetId) {
              delete newLocalStorageQuickPolls[quickPollId].lastWidgetId;
            }
          });

          newLocalStorageQuickPolls[quickPollId] = newLocalStorageQuickPolls[quickPollId] || {};
          newLocalStorageQuickPolls[quickPollId].lastWidgetId = this.widgetId;

          if (this.quickPoll.state !== 'answered') {
            useQuickPollStore().setQuickPollState(quickPollId.toString(), 'viewed');
            newLocalStorageQuickPolls[quickPollId].viewed = true;
          }

          localStorage.setItem('quick-polls', JSON.stringify(newLocalStorageQuickPolls));
        });
      },
      isArticlePoll(): boolean {
        return Boolean(
          this.quickPoll.article?.url &&
            this.quickPoll.article?.title &&
            this.quickPoll.article?.cover,
        );
      },
      onClickedAnswer(quickPollId: number, answerId: number): void {
        useQuickPollStore()
          .updateQuickPollAnswer(quickPollId.toString(), answerId)
          .then(() => {
            const localStorageQuickPolls = JSON.parse(
              localStorage.getItem('quick-polls') as string,
            );
            const newLocalStorageQuickPolls = localStorageQuickPolls || {};

            newLocalStorageQuickPolls[quickPollId] = newLocalStorageQuickPolls[quickPollId] || {};
            newLocalStorageQuickPolls[quickPollId].answer = answerId;

            localStorage.setItem('quick-polls', JSON.stringify(newLocalStorageQuickPolls));

            const answerText = this.quickPoll.answers?.find(({ id }) => id === answerId)?.text;

            this.$sendYandexMetrika({
              level1: 'Квизы_Ответы_Быстрый опрос',
              level5: quickPollId,
              level6: answerText || String(answerId),
            });

            return useQuickPollStore().fetchQuickPollStat(quickPollId);
          });
      },

      onLinkClick(url: string) {
        this.$sendYandexMetrika({
          level1: 'Квизы_Выбор материала_Быстрый опрос',
          level4: url,
          level5: this.quickPollId,
        });
      },
    },
  });
</script>

<style lang="scss">
  .quick-poll {
    &__article {
      max-height: 0;
      overflow: hidden;
      transition: max-height ease-in-out;
      transition-duration: 0.3s;
      transition-delay: 0.3s;

      &--visible {
        max-height: 400px;
        transition: max-height ease-in-out;
        transition-duration: 0.3s;
        transition-delay: 0.3s;
      }
    }
  }
</style>
