<template lang="pug">
    .widgets-rotator
        section(v-for="(item,idx) in items")
            HtmlWidgetContainer(
                :widgetsArray="item"
                v-show="idx === activeWidgetIndex"
            )
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';
  import HtmlWidgetContainer from '~/containers/HtmlWidgetContainer/HtmlWidgetContainer.vue';

  let intervalId: NodeJS.Timeout | null = null;

  function getRandomInteger(min: number, max: number): number {
    const rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
  }

  type TProps = {
    readonly items: PropOptions<TContent[][]>;
    readonly interval: PropOptions<number>;
  };

  export default defineNuxtComponent({
    name: 'WidgetsRotator',
    components: {
      HtmlWidgetContainer,
    },
    props: {
      items: {
        required: true,
        type: Array,
      },
      interval: {
        required: true,
        type: Number,
      },
    } as TProps,
    data() {
      return {
        activeWidgetIndex: 0 as number,
      };
    },
    mounted() {
      intervalId = setInterval(() => {
        this.activeWidgetIndex = getRandomInteger(0, this.items.length - 1);
      }, this.interval * 1000);
    },
    beforeUnmount() {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    },
  });
</script>
