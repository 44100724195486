<template>
  <div class="news-widget">
    <div class="news-widget__headline">Новости</div>
    <div class="news-widget__body">
      <NewsWidgetItem
        v-for="item of items"
        :key="item.id"
        :item="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import NewsWidgetItem from '~/components/NewsWidget/NewsWidgetItem.vue';

  export default defineNuxtComponent({
    name: 'NewsWidget',
    components: {
      NewsWidgetItem,
    },
    props: {
      widget: {
        type: Object as PropType<TWidget>,
        required: true,
      },
    },

    computed: {
      items(): IArticle[] {
        return this.widget.items as IArticle[];
      },
    },
  });
</script>

<style lang="scss" scoped>
  .news-widget {
    border: 1px solid #e7e7e7;
    background: #ffffff;
    padding: 16px 0;

    &__headline {
      padding-left: 16px;
      font-family: $secondFont;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }
  }
</style>
