<template>
  <div
    v-if="!isActive"
    class="news-item"
  >
    <TheLink
      class="news-item__link"
      :link="item.url"
      :title="''"
    />
    <!-- eslint-disable vue/no-v-html -->
    <p
      class="news-item__headline"
      v-html="item.title"
    />
    <span class="news-item__date">
      {{ date }}
    </span>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getDateText } from '@devhacker/shared/utils/date';
  import TheLink from '~/components/TheLink.vue';
  import { getRelativeLink } from '~/utils';

  export default defineNuxtComponent({
    name: 'NewsWidgetItem',

    components: {
      TheLink,
    },

    props: {
      item: {
        type: Object as PropType<IArticle>,
        required: true,
      },
    },

    computed: {
      date(): string {
        return getDateText(this.item.date);
      },
      isActive(): boolean {
        return getRelativeLink(this.item.url) === this.$route.path;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .news-item {
    padding: 12px 16px;
    position: relative;

    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &__headline {
      font-family: $mainFont;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      color: #000;
      overflow-wrap: break-word;
    }

    &__date {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-family: $mainFont;
      color: #5c5c5c;
    }
  }
</style>
