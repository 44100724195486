<template>
  <!-- https://www.figma.com/file/xa8FrgPPuqXTcsfZdtUNZP/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8C%D0%B5?type=design&node-id=1296-157872&mode=design&t=ZxiRTo7fIj7ITSH6-0 -->
  <div
    class="tile-item"
    @click="clickTile"
  >
    <p class="tile-item__heading">
      {{ tile.title }}
    </p>
    <p
      v-if="tile.text"
      class="tile-item__text"
    >
      {{ tile.text }}
    </p>
    <SvgAsset
      v-if="tile.image && !tile.isUploadImage"
      :content="tile.image"
      class="tile-item__image"
    />
    <img
      v-else
      :src="tile.image"
      class="tile-item__image-upload"
    />
    <!-- TODO href -->
    <a
      :href="tile.link"
      class="tile-item__link"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { TTile } from '@/containers/TilesContainer/types';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';

  export default defineNuxtComponent({
    name: 'TileItem',

    components: {
      SvgAsset,
    },

    props: {
      tile: {
        required: true,
        type: Object as PropType<TTile>,
      },
    },

    methods: {
      clickTile() {
        this.$emit('clickTile');
      },
    },
  });
</script>

<style lang="scss" scoped>
  .tile-item {
    height: 100%;
    position: relative;
    border-radius: 4px;
    padding: 8px;
    word-wrap: break-word;
    background-color: #f3f5fc;
    @include hover {
      background-color: var(--bg-color-substrate-2);
    }

    &__heading {
      font-family: $secondFont;
      color: #000;
      font-weight: 600;

      @include smallText;
      @include tablet {
        font-size: 14px;
        line-height: 24px;
      }
      @include big-tablet {
        font-weight: 700;
      }
    }

    &__text {
      font-family: $mainFont;
      color: #000;
      font-weight: 400;

      @include tinyText;
      @include tablet {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &__image {
      position: absolute;
      bottom: 8px;
      right: 8px;
      color: #376dfa;
      svg {
        height: 50px;
        width: 50px;
      }
    }

    &__image-upload {
      position: absolute;
      bottom: 8px;
      right: 8px;
      height: 50px;
      width: 50px;
    }

    &__link {
      &::after {
        top: 0;
        opacity: 0;
        left: 0;
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
