<template lang="pug">
form.lh-poll__wrapper(
  :class="pollWrapperClass"
  :style="cssVariables"
)
  fieldset.lh-poll
    p.lh-poll__question(
      data-jest="question"
    ) {{question}}
    .lh-poll__button-list
      button.lh-poll__button(
        data-jest="button"
        v-for="item of answers"
        :key="item.id"
        @click.prevent="onClickAnswer(id, item.id)"
        :disabled="isAnsweredState"
        :class="getButtonClasses(item.id)"
        :style="isAnsweredState ? {'width': percentageStatistics[item.id] + '%'} : {}"
      )
        .lh-poll__button-content
          TransitionAnimation(
            name="lh-fade"
          )
            span.lh-poll__button-percent(
              v-if="isAnsweredState && isAnsweredStateDelay"
              v-text="getPercentageText(item.id)"
            )
          span.lh-poll__button-percent-filler(
            v-text="getPercentageText(item.id)"
          )
          span.lh-poll__button-text(
            v-text="item.text"
          )
  component.test-pixel-observer(
    :is="isAppearTestPixel ? 'div' : 'IntersectionObserver'"
    @appear="onAppear"
  )
    img.test-pixel(
      v-if="testPixel"
      :src="testPixel"
      alt="Тестовый пиксель"
    )
  // @slot Элемент, который отображется под опросом
  slot
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import TransitionAnimation from '@/components/transition.vue';
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

  type Answer = {
    id: number;
    text: string;
  };

  const THEMES = {
    0: {
      background: '#EEEAF9',
      button: '#9989CC',
    },
    1: {
      background: '#F9E6F1',
      button: '#D272AA',
    },
    2: {
      background: '#E9EEFF',
      button: '#7C8ECD',
    },
  } as Record<string, Record<string, string>>;

  export default defineNuxtComponent({
    name: 'Poll',

    components: {
      TransitionAnimation,
      IntersectionObserver,
    },

    props: {
      id: {
        type: Number as PropType<number>,
        required: true,
      },
      question: {
        type: String as PropType<string>,
        required: true,
      },
      answers: {
        type: Array as PropType<Answer[]>,
        required: true,
      },
      state: {
        type: String as PropType<'initial' | 'viewed' | 'answered'>,
        required: true,
      },
      /**
       * Статистика ответов. Ключ - идентификатор ответа. Значение - количество.
       */
      statistics: {
        type: Object as PropType<{
          [answerId: string]: number;
        }>,
        validator: (item) => item === null || typeof item === 'object',
        default: undefined,
      },
      /**
       * Тема. Определяет цвет фона и кнопок.
       */
      theme: {
        type: String as PropType<'0' | '1' | '2'>,
        default: '0',
      },
      selectedAnswer: {
        type: Number as PropType<number>,
        default: null,
      },
      testPixel: {
        type: String as PropType<string>,
        default: '',
      },
      inSidebar: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      location: {
        type: String as PropType<string>,
        default: '',
      },
    },

    data: () => ({
      isAnsweredStateDelay: false,
      isAppearTestPixel: false,
      isSentAnswer: false,
    }),

    computed: {
      pollWrapperClass(): any {
        return {
          'lh-poll__wrapper--in-sidebar': this.inSidebar,
          [`lh-poll__wrapper--${this.location}`]: this.location ?? null,
        };
      },
      isAnsweredState(): boolean {
        return Boolean(this.state === 'answered' && this.statistics);
      },
      isInitialState(): boolean {
        return this.state === 'initial';
      },
      cssVariables(): Record<string, string> {
        return {
          '--primary-color': THEMES[this.theme].background,
          '--secondary-color': THEMES[this.theme].button,
        };
      },
      percentageStatistics(): Record<string, number> {
        if (!this.statistics) {
          return {};
        }

        let total = 0;
        const result: Record<string, number> = {};
        const statisticsEntries = Object.entries(this.statistics);
        statisticsEntries.forEach(([, count]) => {
          total += count;
        });
        statisticsEntries.forEach(([id, count]) => {
          result[id] = Math.round((count / total) * 100);
        });
        return result;
      },
    },

    watch: {
      isAnsweredState(newValue: boolean): void {
        if (newValue) {
          setTimeout(() => {
            this.isAnsweredStateDelay = true;
          }, 300);
        }
      },
      // При изменении poll.id тестовые пиксели обнуляются
      id(newVal: any, oldValue: any): void {
        if (newVal !== oldValue) {
          this.isAppearTestPixel = false;
        }
      },
    },

    created() {
      this.isAnsweredStateDelay = this.isAnsweredState;
    },

    methods: {
      onAppear(): void {
        if (!this.isAppearTestPixel) {
          this.isAppearTestPixel = true;
          this.$emit('appear', this.id);
        }
      },
      onClickAnswer(pollId: number, answerId: number): void {
        if (this.isSentAnswer) {
          return;
        }

        this.isSentAnswer = true;
        /**
         * Событие генерируется при клике на кнопку ответа. Передается идентификатор ответа.
         */
        this.$emit('clicked-on-answer', pollId, answerId);
      },

      getButtonClasses(id: number): Record<string, boolean> {
        return {
          'lh-poll__button--result': this.isAnsweredState,
          'lh-poll__button--result-selected': this.isAnsweredState && id === this.selectedAnswer,
          'lh-poll__button--result-not-selected':
            this.isAnsweredState && id !== this.selectedAnswer,
        };
      },
      getPercentageText(id: number): string {
        return typeof this.percentageStatistics[id] !== 'undefined'
          ? `${this.percentageStatistics[id]}%`
          : '';
      },
    },
  });
</script>

<style lang="scss" src="./Poll.scss"></style>
