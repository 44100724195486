<template lang="pug">
.taxonomy-posts__container
  template(v-if='articles.length <= 2 && inSidebar')
    component.observer.article-card-square(
      v-for='item of articles',
      :key='item.id',
      :is='getComponent(item)',
      @appear.once='onAppear(item)'
    )
      ArticleCardSquare(
        :title='item.title',
        :link='getLink(article)',
        :linkTarget='article.teaserUrl ? "_blank" : undefined',
        :imgAlt='article.img_alt',
        :images='(item.image && item.image.sizes) || []',
        :defaultImages='item.defaultImg',
        :signText='item.advertLabel',
        :postId='item.id',
        :isPromo='item.isPromo',
        @click-on-article-card='onClickArticleCard(item)'
      )
  template(v-else-if='articles.length === 1')
    component.observer.article-card__large-observer(
      :is='getComponent(articles[0])',
      @appear.once='onAppear(articles[0])'
    )
      ArticleCardLarge.article-card__large(
        v-bind='useArticleProps(articles[0])',
        :loadingViews='getLoadingViews(articles[0].id)',
        :show-category-icon='!isHealth',
        @click-on-restore-button='updateViewsCount(articles[0].id)',
        @favorite-delete='(favorite) => onFavoriteDelete(favorite, articles[0])',
        @favorite-create-folder-and-add='(nameFolder) => onFavoriteCreateFolderAndAdd(nameFolder, articles[0])',
        @favorite-create-folder-and-transfer='(nameFolder, favorite) => onFavoriteCreateFolderAndTransfer(nameFolder, favorite, articles[0])',
        @favorite-transfer-to-folder='(favorite, folder) => onFavoriteTransferToFolder(favorite, folder, articles[0])',
        @favorite-add-to-folder='(folder) => onFavoriteAddToFolder(folder, articles[0])',
        @favorite-deferred='onFavoriteDeferred',
        @click-on-article-card='onClickArticleCard(articles[0])',
        @clicked-on-category='onClickCategory'
      )
  template(v-else-if='articles.length === 2')
    component.observer.article-card__middle-observer(
      v-for='article of articles',
      :key='article.id',
      :is='getComponent(article)',
      @appear.once='onAppear(article)'
    )
      ArticleCardMiddle.article-card__middle(
        v-bind='useArticleProps(article)',
        :loadingViews='getLoadingViews(article.id)',
        @click-on-restore-button='updateViewsCount(article.id)',
        @favorite-delete='(favorite) => onFavoriteDelete(favorite, article)',
        @favorite-create-folder-and-add='(nameFolder) => onFavoriteCreateFolderAndAdd(nameFolder, article)',
        @favorite-create-folder-and-transfer='(nameFolder, favorite) => onFavoriteCreateFolderAndTransfer(nameFolder, favorite, article)',
        @favorite-transfer-to-folder='(favorite, folder) => onFavoriteTransferToFolder(favorite, folder, article)',
        @favorite-add-to-folder='(folder) => onFavoriteAddToFolder(folder, article)',
        @favorite-deferred='onFavoriteDeferred',
        @click-on-article-card='onClickArticleCard(article)',
        @clicked-on-author='onClickAuthor',
        @clicked-on-category='onClickCategory'
      )
  template(v-else-if='articles.length >= 3')
    TheSlider(
      :sliderList='getSliderList(articles)',
      :sliderTitle='title',
      @appear-item='onAppear',
      @onClickSlide='sendAnalytics'
    )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import ArticleCardMiddle from '@/components/lh-ui/ArticleCard/ArticleCardMiddle/ArticleCardMiddle.vue';
  import ArticleCardLarge from '@/components/lh-ui/ArticleCard/ArticleCardLarge/ArticleCardLarge.vue';
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
  import TheSlider from '~/components/slider/TheSlider/TheSlider.vue';
  import ArticleCardSquare from '~/components/ArticleCardSquare.vue';
  import { getFullUrl, getRelativeLink, isHomeLink } from '~/utils';
  import { eventAction } from '~/constants/analytics';
  import {
    isArticlePage,
    isHomePage,
    isAnyRecipePage,
    isHealthPath,
    isHealthMain,
  } from '~/utils/router';
  import { useFavorite } from '@/composables/useFavoriteMethods';
  import { useUpdatingViewsCount } from '@/composables/useUpdatingViewsCount';
  import { useArticleProps } from '~/composables/useArticleProps';
  import { useFoldersStore } from '~/store/folders';
  import { useIndexStore } from '~/store';

  export default defineNuxtComponent({
    name: 'TaxonomyPostsContainer',

    eventAction,

    components: {
      ArticleCardMiddle,
      ArticleCardLarge,
      TheSlider,
      ArticleCardSquare,
      IntersectionObserver,
    },

    setup() {
      const {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
      } = useFavorite();
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },

    props: {
      articles: {
        type: Array as PropType<IArticle[]>,
        required: true,
      },
      inSidebar: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      title: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
    },

    data() {
      return {
        articlesWithLoadedPixels: [] as number[],
      };
    },

    computed: {
      folders() {
        return useFoldersStore().$state;
      },

      user() {
        return useIndexStore().user;
      },

      isHealth(): boolean {
        return isHealthPath(useRoute().path);
      },

      isHealthMain(): boolean {
        return isHealthMain(useRoute().name);
      },

      isAuthenticatedInWordpress(): boolean {
        return useIndexStore().isAuthenticatedInWordpress;
      },
    },

    watch: {
      '$route.fullPath'() {
        this.articlesWithLoadedPixels = [];
      },
    },

    methods: {
      useArticleProps,

      // TODO: Check if something breaks when using composable
      // TO DO: похожий метод в
      // src/compasable/useArticleProps.ts,
      // src/containers/ZenRotationContainer.vue
      // можно объединить, при этом ВАЖНО сохранить значения и набор свойств, тк они отличаются
      // в миксинах и в виджете, например title
      // В миксине выбирается тестовый заголовок и сохраняется в сторе если он есть.
      // А в виджете только выбирается сохраненный тестовый заголовок из стора если он
      // есть или основной заголовок. Плюс в миксине кроме этого метода есть еще другие,
      // которые не нужны в виджете
      // getArticleProps(article: IWidgetArticle): { [propName: string]: any } {
      //   const {
      //     id,
      //     categories,
      //     author,
      //     url: articleUrl,
      //     isAdvertising,
      //     isPromo,
      //     commentsEnabled,
      //     img,
      //     defaultImg,
      //     date,
      //     img_alt,
      //     title,
      //     teaserUrl,
      //     advertLabel,
      //   } = article;
      //
      //   const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      //   const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);
      //
      //   return {
      //     categories,
      //     isPromo,
      //     isAdvertising: isAdvertising && !ADS_EXCEPTIONS.includes(advertLabel),
      //     isTeaser: !!teaserUrl,
      //     commentsEnabled,
      //     internalUrl,
      //     url,
      //     advertLabel,
      //     partnerText: ADS_LABELS[advertLabel],
      //     imgAlt: img_alt,
      //     title,
      //     images: img,
      //     defaultImages: defaultImg,
      //     authorText: author?.name || '',
      //     authorUrl: author?.profile || '',
      //     inFavorites: this.getArticleFavoriteId(id) > 0,
      //     dateText: this.isHealthMain ? getDateText(date) : getFormattedDate(date),
      //     commentsCount: this.getCommentsCount(id),
      //     isAuthenticatedInWordpress: this.isAuthenticatedInWordpress,
      //     editLinkHref: getEditLinkHref(id),
      //     viewsCount: this.getViewsCount(id),
      //     user: this.$store.state.user as IRootState['user'],
      //     folders: this.$store.state.folders as FoldersState,
      //     favorite: this.$store.getters.getArticleFavorite(id) as IFavoriteItem,
      //     favoriteMenuOffsets: {
      //       top: 48, // учитывать fixed шапку
      //     } as TOffsets,
      //   };
      // },

      isTrackTheAppearance(article: IArticle): boolean {
        return Boolean(article?.testPixel);
      },

      getComponent(article: IArticle): { is: string } {
        const componentName = this.isTrackTheAppearance(article) ? 'IntersectionObserver' : 'div';
        return componentName;
      },

      onAppear(article: IArticle): void {
        const testPixel = article?.testPixel;
        const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
        if (testPixel?.length && isNotLoadedPixel) {
          testPixel.forEach((url) =>
            $fetch(url, { credentials: 'include' }).catch((error) => {
              this.$logger(
                'Error in sending text pixels for ' + article + ' in TaxonomyPostsContainer.vue',
                error,
              );
            }),
          );
          this.articlesWithLoadedPixels.push(article.id);
        }
      },

      getSliderList(articles: IArticle[]): IArticle[] {
        return articles.map((item) => ({
          ...item,
          title: item.title,
          images: item.image && item.image.sizes,
          defaultImg: item.defaultImg,
        }));
      },

      onClickArticleCard(article: IArticle): void {
        const { name } = useRoute();
        const category = isHomePage(name) ? 'Главная' : isArticlePage(name) ? 'Внутренние' : '';
        const { teaserUrl } = article;
        const url = teaserUrl || getFullUrl(article.url);
        if (category) {
          this.$sendYandexMetrika({
            level1: `Выбор материала_таксономия_${category}`,
            level4: url,
            level6: 'Тизер',
          });
        }

        if (isHomePage(name) && teaserUrl) {
          this.$sendYandexMetrika({
            level1: `Выбор материала_таксономия_${category}`,
            level4: url,
            level6: 'Тизер',
          });
        }

        this.sendAnalyticsArticle(url);
      },

      sendAnalyticsArticle(url: string) {
        if (isHomePage(useRoute().name)) {
          return;
        }

        const articleIndex = this.articles.findIndex(
          (elem) => getFullUrl(elem?.url) === url || elem?.teaserUrl === url,
        );
        const event = 'Клик_Лучшие предложения';

        this.$sendAnalyticsSnowPlow({
          event_name: event,
          par3: this.articles[articleIndex]?.url,
          par4: String(articleIndex + 1),
        });

        this.$sendYandexMetrika({
          level1: 'Выбор материала_Виджет большая карточка',
          level4: getFullUrl(this.articles[articleIndex]?.url),
        });
      },

      sendAnalytics(link: string) {
        if (!isAnyRecipePage(useRoute().name)) {
          this.sendAnalyticsArticle(link);
        }
      },

      onClickCategory(link: string, titleCategory: string, _title: string) {
        this.$sendYandexMetrika({
          level1: 'Выбор рубрики',
          level4: getFullUrl(link),
          level5: titleCategory,
          level6: 'unknown',
        });
      },

      getLink(article: IArticle) {
        const { url: articleUrl, teaserUrl } = article;

        const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
        const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);

        return url;
      },
    },
  });
</script>

<style lang="scss">
  .taxonomy-posts__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .slider {
      border-left-width: 0;
      border-right-width: 0;
    }

    .home-page-after-tv-widgets & .slider {
      border-top: 0;
    }
  }

  .article-card-square:not(:last-child) {
    margin-bottom: 24px;
  }

  .article-card__large-observer {
    width: 100%;

    @include tablet {
      width: unset;
    }

    @include widget-above-comments {
      max-width: 780px;
    }

    @include widget-below-comments {
      .lh-large-article-card__wrapper {
        min-width: auto !important;
        max-height: 360px;
        img {
          object-fit: cover;
        }
      }

      @include one-column {
        .lh-large-article-card__wrapper {
          @include tablet {
            max-height: 320px;
            max-width: 640px;
          }
          @include big-tablet {
            max-width: 780px;
            max-height: 420px;
          }
        }
      }

      @include broadside {
        .lh-large-article-card__wrapper {
          @include tablet {
            max-height: 320px;
            max-width: 640px;
          }
          @include big-tablet {
            max-height: 384px;
            max-width: 732px;
          }
        }
      }

      @include two-column {
        .lh-large-article-card__wrapper {
          @include tablet {
            max-height: 320px;
            max-width: 640px;
          }
          @include desktop {
            max-width: 780px;
            max-height: 420px;
          }
        }
      }

      @include template-table-of-contents {
        .lh-large-article-card__wrapper {
          @include tablet {
            max-width: 640px;
            max-height: 320px;
          }
          @include desktop {
            max-width: 780px;
            max-height: 420px;
          }
        }
      }
    }
  }

  .article-card__large {
    @include widget-above-comments {
      max-width: 640px;

      @include tablet {
        height: 320px;
      }

      .lh-large-article-card__title {
        @include tablet {
          @include fontH2;

          color: white;
        }
      }
    }

    @include hover {
      z-index: 5;
    }
  }

  .article-card__middle-observer {
    width: 100%;

    @include tablet {
      width: unset;
    }

    @include widget-above-comments {
      @include tablet {
        max-width: 320px;

        .article-card__middle {
          max-width: 320px;
          height: 344px;

          &:last-child {
            width: 321px;
            max-width: 321px;
          }
        }
      }

      @include hover {
        .lh-middle-article-card__title {
          color: var(--hover-color);
        }
      }

      .lh-middle-article-card__title {
        @include tablet {
          @include fontH4;
        }
      }
    }
    &:last-child {
      margin-top: -1px;
    }

    @include tablet {
      width: 50%;

      .article-card__middle {
        width: 100%;
        max-width: 100%;
      }

      &:last-child {
        margin-top: 0;
        margin-left: -1px;
        width: calc(50% + 1px);
        max-width: calc(50% + 1px);

        .article-card__middle {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    @include hover {
      z-index: 5;
    }
  }
</style>
