<!-- https://www.figma.com/file/xa8FrgPPuqXTcsfZdtUNZP/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8C%D0%B5?type=design&node-id=1296-157872&mode=design&t=ZxiRTo7fIj7ITSH6-0 -->

<template>
  <div
    class="tiles-rubrics"
    :class="computedClass"
    :style="computedStyles"
  >
    <TileContainer
      :tiles="tiles"
      :show-arrows="showArrows"
      :enable-swiper="enableSwiper"
      @click-tile="sendAnalytics"
      @scroll-swiper="sendScrollAnalytics"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { Img } from '@devhacker/shared/utils/image/types';
  import { getFullUrl, isMobile, isTablet, trailingSlash } from '~/utils';
  import TileContainer from '@/containers/TilesContainer/TileContainer.vue';
  import type { TTile } from '@/containers/TilesContainer/types';

  type catsItem = {
    author: undefined | string;
    icon: string;
    image: Array<Img>;
    name: string;
    profile: undefined | string;
    slug: string;
    url: undefined | string;
  };

  export default defineNuxtComponent({
    name: 'TilesRubrics',

    components: {
      TileContainer,
    },

    props: {
      items: {
        type: Array as PropType<Array<catsItem>>,
        required: true,
      },
    },

    data(): { tiles: Array<TTile> | []; enableSwiper: boolean; showArrows: boolean } {
      return {
        tiles: [],
        enableSwiper: false,
        showArrows: false,
      };
    },

    setup(props) {
      const tiles = ref<TTile[]>([]);

      tiles.value = props.items.map((item) => {
        return {
          title: item.name,
          image: item.icon,
          link: trailingSlash(item.slug),
          text: '',
          isUploadImage: true,
        };
      });

      return {
        tiles,
      };
    },

    computed: {
      computedStyles(): string {
        return `
      --tile-width-mobile: ${this.enableSwiper ? '40%' : '0'};
      --tile-width-tablet: ${this.enableSwiper ? '147px' : '0'};
      --tile-flex: ${this.enableSwiper ? '1 1 auto' : '1 1 0'};
      `;
      },
      computedClass(): Record<string, boolean> {
        return {
          'tiles-rubrics-disabled-swaper': !this.enableSwiper,
        };
      },
    },

    mounted() {
      if (isMobile()) {
        this.enableSwiper = true;
      } else if (isTablet() && this.tiles.length > 5) {
        this.enableSwiper = true;
      } else {
        this.showArrows = this.tiles.length >= 6;
        this.enableSwiper = this.tiles.length >= 6;
      }
    },

    methods: {
      sendAnalytics(tile: TTile) {
        this.$sendYandexMetrika({
          level1: 'Клик_Плитка рубрик',
          level4: getFullUrl(`/health/${tile.link}/`),
          level6: tile.title,
        });
      },

      sendScrollAnalytics() {
        this.$sendYandexMetrika({
          level1: 'Скролл_Плитка рубрик',
          level6: '1',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .tiles-rubrics {
    padding: 16px 0;
    @include tablet {
      padding: 24px 0;
    }
    :deep() {
      .tiles {
        @include desktop {
          gap: 12px;
        }
      }
      .tile-item__image {
        height: 50px;
        width: 50px;
      }
      .svg-icon {
        mask-size: 46px;
        @include tablet {
          mask-size: 51px;
        }
      }

      .swiper-slide {
        min-width: var(--tile-width-mobile);
        max-width: 40%;
        flex: var(--tile-flex);
        height: 90px;

        @include tablet {
          min-width: var(--tile-width-tablet);
          max-width: 25%;
          flex: var(--tile-flex);
        }
      }
    }
  }

  .tiles-rubrics-disabled-swaper {
    padding: 16px 0 16px 16px;
    @include tablet {
      padding: 24px 12px 24px 24px;
    }
  }
</style>
